
























































import { Component, Vue } from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'
import { apiBannerAdd, apiBannerDetail, apiBannerEdit } from '@/api/imagetru3d'
@Component({
  components: {
    MaterialSelect,
  },
})
export default class BannerEdit extends Vue {
  $refs!: { form: any }
  id!: any
  loading = false
  form = {
    name: '',
    uri: '',
    sort: '',
    show: 1,
  }

  rules = {
    uri: [
      {
        required: true,
        message: '请选择轮播图片',
        trigger: ['blur', 'change'],
      },
    ],
  }

  // 新增 / 编辑轮播图
  handleSave() {
    this.$refs.form.validate((valid: boolean) => {
      if (valid) {
        // 如果排序没有填写，默认是255
        if (!this.form.sort) {
          this.form.sort = '255'
        }
        // 判断是编辑还是新增
        const api = this.id ? apiBannerEdit(this.form) : apiBannerAdd(this.form)
        // 新增或编辑后，返回上一界面
        api.then(() => {
          this.$router.go(-1)
          if (this.id) {
            this.$message.success('修改轮播图成功')
          } else {
            this.$message.success('新增轮播图成功')
          }
        })
      } else {
        return false
      }
    })
  }

  // 轮播图详情
  getBannerDetail() {
    this.loading = true
    apiBannerDetail(this.id).then((res: any) => {
      this.form = res
      this.loading = false
    })
  }

  created() {
    this.id = this.$route.query.id
    this.id && this.getBannerDetail()
  }
}
